<template>
    <v-dialog
      :value="true"
      @click:outside="$emit('close')"
      v-resize="onResize"
      :fullscreen="$vuetify.breakpoint.xs  "
      :width="width"
    >
      <v-card tile>
        <v-card-title
          class="text-h6 success white--text d-flex justify-space-between"
          style="position: sticky; top:0; z-index: 10;"
        >
          <div>{{$t('projects.campingDays.title')}}</div>
          <div class=""><v-icon class="white--text" @click="$emit('close')">mdi-close</v-icon></div>
        </v-card-title>

        <v-card-text>
          
          <v-row>
            <v-col cols="12" md="6"  class="">
              <div>
                <vueper-slides
                  :dragging-distance="50"
                  :bullets="false"
                  :pauseOnHover="false"
                  :duration=9999
                  :arrows="true"
                  :autoplay="true"
                  class="mt-5"
                  fixed-height="400px"
                >
                  <vueper-slide  
                    v-for="img in images1" 
                    :image="`/images/projects/diascampo/${img}`"
                    :key="img"
                  >
                  </vueper-slide>
                </vueper-slides>

              </div>
              <div class="caption">{{$t('projects.campingDays.text1')}}</div>
            </v-col>
            <v-col cols="12" md="6" class="">
              <div>
                <vueper-slides
                  :dragging-distance="50"
                  :bullets="false"
                  :pauseOnHover="false"
                  :duration=9999
                  :arrows="true"
                  :autoplay="true"
                  class="mt-5"
                  fixed-height="400px"
                >
                  <vueper-slide  
                    v-for="img in images2" 
                    :image="`/images/projects/diascampo/${img}`"
                    :key="img"
                  >
                  </vueper-slide>
                </vueper-slides>
              </div>
              <div class="caption">{{$t('projects.campingDays.text2')}}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'

  export default {
    name: 'DiasCampo',
    components: { VueperSlides, VueperSlide },


    data: () => ({
      width:'80vw',
      images1:[
        'IMG_1_1.jpg',
        'IMG_1_2.jpg',
        'IMG_1_3.jpg',
        'IMG_1_4.jpg',
        'IMG_1_5.jpg',
      ],
      images2:[
        'IMG_2_1.jpg',
        'IMG_2_2.jpg',
        'IMG_2_3.jpg',
        'IMG_2_4.jpg',
        'IMG_2_5.jpg'
      ]
    }),

    methods: {
      onResize () {
        console.log(this.$vuetify.breakpoint)
        let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint  
        
        let r = {}
        if (xl) {
          this.width='50vw'
        }
        else if (lg) {
          this.width='60vw'
        }
        else if (md) {
          this.width='80vw'
        }


        //console.log(window.orientation)


        return r
        
      },
    },
  }
</script>
