import Vue from 'vue'
import VueRouter from 'vue-router'
import Nosotros from '../views/Nosotros.vue'
import Proyectos from '../views/Proyectos.vue'
import Productos from '../views/Productos.vue'
import Contacto from '../views/Contacto.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/projects',
    name: 'Proyectos',
    component: Proyectos
  },
  {
    path: '/products',
    name: 'Productos',
    component: Productos
  },
  {
    path: '/contact',
    name: 'Contacto',
    component: Contacto
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
