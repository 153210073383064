<template>
  <div class="mt-5 mx-0" >

    <v-row no-gutters class="mb-5">
      <v-col cols="10" class="offset-1">
        <div class="d-flex align-center">
          <v-img 
            src="/images/icono_nosotros.png"
            max-width="42"
          >
          </v-img>
          <span class="mx-5 text-h6 font-weight-bold">{{ $t('about_us.section') }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10" md="4" class="offset-1">
        <v-row  >
          <v-col cols="12"  v-html="$t('about_us.text')"></v-col>
        </v-row>
        <v-row  >
          <v-col cols="6" class=" ">
            <v-btn
              block
              color="#8BC53F"
              elevation="2"
              height="48px"
              tile
              class="rounded-tl-xl text-sm-h6 white--text"
              @click="Goto ('Proyectos',1,'link')"
            >
              {{$t('about_us.projects')}}
            </v-btn>
          </v-col>
          <v-col cols="6" class="">
            <v-btn
              block
              color="#9B5F17"
              elevation="2"
              height="48px"
              tile
              class="rounded-tl-xl text-sm-h6 white--text"
              @click="Goto ('Productos',2,'link')"
            >
              {{$t('about_us.catalogue')}}
            </v-btn>
            
          </v-col>
        </v-row>
        <!--<div class="zona">1</div>-->
      </v-col>
      <v-col cols="10" md="5" class="offset-1 mt-10 mt-md-0">
      <v-sheet
        color="grey lighten-4"
        width="100%"
        class="rounded-tl-xl rounded-bl-xl rounded-br-xl"
      >
        <v-img contain width="100%" :src="`/images/about_us/${$t('about_us.img')}`" />
      </v-sheet>
      
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10" class="offset-1 pb-3 pt-0 mt-10 pb-0 mb-0 d-flex justify-center text-h6 font-weight-bold grey--text" >{{$t('about_us.they_trust_us')}}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10" class="offset-1 pb-3 pt-0" >
        <transition 
          name="custom-classes-transition"
          enter-active-class="animate__animated animate__fadeInRight animate__delay-0s"
          leave-active-class="animate__animated animate__fadeOut animate__delay-0s"
        >
        <carousel
          
          :loop="true"
          :autoplay="true"
          :nav="false"  
          :margin=0
          :autoWidth = false
          :dots = false
          :items="clientesPorVista" 
          :smartSpeed="3000"
          :key="clientesPorVista"

          :autoplayTimeout=2000
          :autoplayHoverPause='true'

          >
            <v-img
              v-for="(cliente, index) of Clientes"
              :src="cliente.img"
              :eager="true"
              max-height="60px"
              contain          
              :title="cliente.nombre"
              :key="index"
            >
            </v-img>               
        </carousel>
        </transition> 
      </v-col>
    </v-row>

  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'

export default {
  name: 'Home',
  components: {carousel},
  data: () => ({
    clientes:[
      {nombre:'Vivero San Nicolas', img:'/images/clientes/ViveroSanNicolas.png'},
      {nombre:'Tecnosiembra', img:'/images/clientes/Tecnosiembra.png'},
      {nombre:'Salto De Las Rosas', img:'/images/clientes/SaltoDeLaRoca.png'},
      {nombre:'RPB', img:'/images/clientes/RPB.png'},
      {nombre:'Proplanta', img:'/images/clientes/ProPlanta.png'},
      {nombre:'Fitotec', img:'/images/clientes/FitoTec.png'},

    ],
  }),

created: async function () {
    //console.log('create app')

    //console.log(this.$vuetify.breakpoint)
    //this.offsetTop=window.scrollY
    
      
  },

  mounted: function (){
    //console.log("routes-->", this.$route)
    //let {name,params,fullpath,path} = this.$route 
    //if (name=='Secciones') this.$nextTick( function () { this.$vuetify.goTo(`#${params.seccionID}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})})
  },

  watch: {

  },
  computed:{
    //...mapGetters(['ROOT_API','MENU','getSnackBar','getSESSION','getUser']),


    onResize: function () {
      console.log("onResize HOME",this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint  
      let  orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation    

      console.log("orientation-->",orientation)
      console.log({xs, sm, md, lg, xl,mobile})
      console.log("screen-->",screen)

      if (orientation=='landscape-primary' ) {
       // ( sm || xs || screen.height<520) ? this.heightSlide='100vh' : this.heightSlide='63vh' 
      }
      else {
        //this.heightSlide='63vh' 
      }

      return sm || xs


      //this.$store.dispatch("resizeApp",{ x: window.innerWidth, y: window.innerHeight, xs, sm, md, lg, xl,mobile })
    },

    clientesPorVista: function  (){
      let {xs, sm, md, lg, xl} =  this.$vuetify.breakpoint

      let item = 1

      if(xl) item = 6
      if(lg) item = 5
      if(md) item = 3 
      if(sm) item = 2
      if(xs) item = 1

      //console.log("ITEMVIEW-->",this.clientes.length < item ? this.clientes.length : item)

      return (this.clientes.length < item ? this.clientes.length : item )
    },


    Clientes: function (){


      let clientes=[]
      let cantItem = this.clientesPorVista * 10
      clientes.push(...this.clientes)


      //console.log("modulo-->",clientes.length % 3)
      if (clientes.length % cantItem == 0) return this.clientes
      
      //clientes.push(...this.clientes)
      //console.log("modulo-->",clientes.length % 3)
      //clientes.push(...this.clientes)
      //console.log("modulo-->",clientes.length % 3)
      for (let i=0;clientes.length % cantItem != 0; i++){
          clientes.push(...this.clientes)
      }

      //console.log("clisntes-->",clientes)
      //console.log("modulo-->",clientes.length % 5)

   

      return clientes
    }

  },

  methods: {

    onScroll (e){
      //console.log({e, window})
    },

    Goto (url,idx,type){
      let {name,params,fullpath,path} = this.$route 
      if (type=='anclaje'){
        this.$vuetify.goTo(`#${url}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
      }
      else {
        this.$vuetify.goTo(0,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
        if (name!=url){
          this.$router.replace({name:url})
        }

      }
    },

  },




}
</script>

<style lang="scss" scoped>
.zona {
  width: 35px;
  height: 50px;
  background-color:red;
  display:flex;
  justify-content: center;
  align-items:center;
  color:white;
  position: relative;

  font-size: 24px;
  font-weight: bold;
  font-family: 'Poppins';


  &::before {
    position:absolute;
    content: "";
    width:30px;
    height:50px;
    background-color:red;
    left:24px;
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -ms-transform: skew(-25deg);
    -o-transform: skew(-25deg);
    transform: skew(-25deg), 

    
  }
  
}
</style>
