<template>
  <v-app  v-resize="onResize">
      <v-app-bar
        v-show="!conocenos"
        :app="true"
        elevation="0"
        color="#231E32"
        dark
        style="position: sticky; top:0; z-index: 10;"
        key="appBar"
        
      >
        <div class="d-flex align-center">
          <a href="/">
            <v-img
              
              alt="CuyoSeeds Logo"
              class="shrink mr-2"
              contain
              src="/images/logo.png"
              transition="scale-transition"
              width="120"
            />
          </a>
        </div>
        
        <v-spacer></v-spacer>
        <template v-if="!isSmall">
 
          <v-btn-toggle tile group mandatory active-class="btn-nav" v-model="menuPpal">

            <v-btn v-for = "item of Menu" 
              :key="item.title"
              :text="item.text"
              :tile="item.tile" 
              :replace="item.replace" 
              :ripple="item.ripple"
              :rounded="item.rounded"
              :class="item.class"
              :color="item.color"
              @click.native="Goto(item.link,item.type)"

            >
              {{$t(`menu.${item.title}`)}}
            </v-btn>

          </v-btn-toggle>
        </template>
        
        <div class="body-2 ml-10"><span class="link" :class="$i18n.locale=='es'?'primary--text font-weight-bold':'white--text'" @click="$i18n.locale='es'">ES</span><span class="mx-2">/</span><span  class="link" :class="$i18n.locale=='en'?'primary--text font-weight-bold':'white--text'" @click="$i18n.locale='en'">EN</span></div>
        <v-app-bar-nav-icon v-if="isSmall" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>

      </v-app-bar>
    <transition name="fade">   
      <v-img
        v-show="conocenos"
        eager
        max-height="100vh"
        min-height="100vh"
        position="top left"
        src="/images/conocenos.png"
      >
        <div  class="d-flex flex-column justify-space-around" style="height: 100vh;">
          <div>
            <v-row no-gutters>
              <v-col cols="10" class="offset-1"  >
                <v-img
                  eager
                  src="/images/logo.png"
                  max-width="16%"
                  min-width="180px"
                 
                >
                </v-img> 
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="10" class="offset-1 white--text text-h6" >{{ $t('conocenos.message') }}</v-col>
            </v-row>
          </div>
          <div class="link" @click="conocenos=false">
            <v-row no-gutters>
              <v-col cols="10" class="offset-1 d-flex justify-center white--text text-h5" >{{ $t('conocenos.title') }}</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="10" class="offset-1 d-flex justify-center " ><v-icon color="white" size="72" class="scroll">mdi-chevron-down</v-icon></v-col>
            </v-row>
          </div>
        </div>
      </v-img>
    </transition>
    <transition-group name="fade">


      <template v-if="isSmall">
        <v-navigation-drawer
          v-model="drawer"
  
          fixed
          temporary
          
          width="100vw"
          height="100vh"
          color="black"
          class=""
          key="menuMobil"
        > 
        <v-btn
          icon
          id="btnClose"
          style="position:fixed; top:10px; right:10px;"
        >
          <v-icon color="primary" size=36 @click="drawer=!drawer">mdi-close</v-icon>
        </v-btn>
          <v-sheet height="100vh" color="black" class="d-flex justify-center">


              <v-btn-toggle 
                tile 
                color="primary"
                group
                mandatory 
                active-class="btn-nav" 
                v-model="menuPpal" 
                class="d-flex flex-column justify-center " 

                
              >
                <v-btn v-for = "(item, index) of Menu" 
                  :key="item.title"
                  :text="item.text"
                  :tile="item.tile" 
                  :replace="item.replace" 
                  :ripple="item.ripple"
                  class="text-h5 btn-line white--text my-8"
                  
                  
                  @click.native="Goto(item.link,item.type)"

                >
                  {{$t(`menu.${item.title}`)}}
                </v-btn>
              </v-btn-toggle>
          </v-sheet>

        </v-navigation-drawer>

    </template> 


    </transition-group >
    <transition name="fade">
      <v-main fluid v-show="!conocenos" id='main' class="pa-0">
        <vueper-slides
          v-if="!conocenos"
          :dragging-distance="50"
          :bullets="false"
          :pauseOnHover="false"
          :duration=9999
          :arrows="false"
          :fixed-height='heightSlide'
          :autoplay="true"
        >
          <vueper-slide 
          
            image="/images/sliders/img1.jpg"
          >
            <template #content>
              <v-sheet
                color="transparent"
                elevation=0
                class="fill-height pa-1 pa-md-16"
                width="100%"
              >

                <v-row
                  class="fill-height"
                  align="center"
                  direction=colum
                >
                  <v-col cols=12  class="pt-10 d-block justify-center text--shadow" >
                    <div class="mb-10 font-weight-bold text-h5 text-md-h4 text-lg-h3 white--text text-center">{{ $t('sliders.slider1.title') }}</div>
                    <div class="my-10 mb-10 text-h6 text-md-h5 text-lg-h4 white--text text-center">{{ $t('sliders.slider1.subtitle')}}</div>             
                  </v-col>
                </v-row>
              </v-sheet> 
            </template>
          </vueper-slide>
          <vueper-slide 
          
            image="/images/sliders/img2.jpg"
          >
            <template #content>
              <v-sheet
                color="transparent"
                elevation=0
                class="fill-height pa-1 pa-md-16"
                width="100%"
              >

                <v-row
                  class="fill-height"
                  align="center"
                  direction=colum
                >
                  <v-col cols=12  class="pt-10 d-block justify-center text--shadow" >
                    <div class="mb-10 font-weight-bold text-h5 text-md-h4 text-lg-h3 white--text text-center">{{ $t('sliders.slider2.title') }}</div>
                    <div class="my-10 mb-10 text-h6 text-md-h5 text-lg-h4 white--text text-center">{{ $t('sliders.slider2.subtitle')}}</div>             
                  </v-col>
                </v-row>
              </v-sheet> 
            </template>
          </vueper-slide>
          <vueper-slide 
          
            image="/images/sliders/img3.jpg"
          >
            <template #content>
              <v-sheet
                color="transparent"
                elevation=0
                class="fill-height pa-1 pa-md-16"
                width="100%"
              >

                <v-row
                  class="fill-height"
                  align="center"
                  direction=colum
                >
                  <v-col cols=12  class="pt-10 d-block justify-center text--shadow" >
                    <div class="mb-10 font-weight-bold text-h5 text-md-h4 text-lg-h3 white--text text-center">{{ $t('sliders.slider3.title') }}</div>
                    <div class="my-10 mb-10 text-h6 text-md-h5 text-lg-h4 white--text text-center">{{ $t('sliders.slider3.subtitle')}}</div>             
                  </v-col>
                </v-row>
              </v-sheet> 
            </template>
          </vueper-slide>
        </vueper-slides>
        <router-view />
        <v-fab-transition>
          <v-btn           
            dark
            small
            bottom
            fab
            class="primary"
            elevation="0"
            right
            fixed
            style="z-index:99999"
            :href="`https://api.whatsapp.com/send?phone=+5492613020767&text=${$t('whatsapp')}`"
            target="blank"
          >
            <v-icon color="white" class="btn-float"> mdi-whatsapp</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-main>
    </transition>  
    <v-footer  v-if="!conocenos"  color="black" dark  style="" class="mt-10 d-block">
        <v-row class="pt-3 px-5">
          <v-col cols=8 md=6 lg=4 class="caption text--white ">
            <ul  class="foot px-3 d-block  " >
              <li  v-for = "(item,index) of Menu" :key="item.title" 
                class="text-uppercase py-1 mx-n2 text--caption  " 
                @click="Goto(item.link,index,item.type)" 
                style="cursor:pointer; list-style-type:none"
                :class="index==menuPpal ? 'primary--text':''"
              >
                {{$t(`menu.${item.title}`)}}
              </li>
            </ul> 
          </v-col>
          <v-col cols=4 md=6 lg=8 class="d-flex justify-end align-start">
              <v-hover
                v-for="ico of icons" :key="ico.ico"
                v-slot="{ hover }"
                open-delay="100"
                close-delay="100"
              >
                <a :href="ico.link" target="_blank" style="text-decoration:none">
                  <v-icon class="mx-2" :color="hover ? 'rgba(255,255,255,0.5)' : 'white'" >{{ico.ico}}</v-icon>
                </a>
                
              </v-hover>  
          </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row  class="my-0 py-2 px-5">
          <v-col cols=12 class="caption tex--white">
            Copyright CUYO SEEDS | 2022.<br v-if="isSmall" /> Todos los derechos reservados.  Multiportal S.A
          </v-col>
        </v-row>
    </v-footer>
  </v-app>
</template>

<script>

import 'animate.css'

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import carousel from 'vue-owl-carousel'

export default {
  name: 'App',
  components: { carousel , VueperSlides, VueperSlide },

  data: () => ({
    conocenos:true,

    icons:[
      {
        ico:'mdi-linkedin',
        title:'Linkedin',
        link:'http://linkedin.com/in/luis-eduardo-cuitiño-olarte-1483503a',
        fn:''
      }
    ],
    
    languaje:0,
    Menu:[
      {title:'us',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text text-uppercase",link:'Nosotros',type:'link'},
      {title:'projects',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text text-uppercase",link:'Proyectos',type:'link'},
      {title:'products',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text text-uppercase",link:'Productos',type:'link'},
      {title:'contact',text:true, tile:true, replace:true, ripple:false, rounded:false,class:"btn-line body-2 white--text text-uppercase",link:'Contacto',type:'link'},
    ],
    Lenguajes:[{lenguaje:'spanish',letter:'es'},{lenguaje:'english',letter:'en'}],


    heightSlide:'45vh',

    isSmall: true,
    drawer:false,
  }),

  created: async function () {

    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
        lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1)
        shortLang = shortLang.split('-')[0];

    if (shortLang.indexOf('_') !== -1)
        shortLang = shortLang.split('_')[0];

    shortLang=='es' ? this.$i18n.locale='es' : this.$i18n.locale='en'



    this.onResize()



    //this.onResize()
      
  },
  computed:{
    menuPpal:{
      // getter
      get() {
        return this.$store.state.menu
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.$store.commit('setMenu',newValue)
      }
    }
  },
  
  methods: {

    onResize () {
      console.log("onResize HOME",this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint  
      let  orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation    

      console.log("orientation-->",orientation)
      console.log({xs, sm, md, lg, xl,mobile})
      console.log("screen-->",screen)

      if (orientation=='landscape-primary' ) {
        ( sm || xs || screen.height<520) ? this.heightSlide='100vh' : this.heightSlide='45vh' 
        //this.heightSlide='100vh'
      }
      else {
        this.heightSlide='45vh' 
      }



      this.isSmall = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
      //return sm || xs

    },  

    Goto (url,idx,type){
      if(idx!=null) this.menuPpal=idx

      let {name,params,fullpath,path} = this.$route 
      if (type=='anclaje'){
        this.$vuetify.goTo(`#${url}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
      }
      else {
        this.$vuetify.goTo(0,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
        if (name!=url){
          this.$router.replace({name:url})
        }

      }
    },
  }
};
</script>
<style lang="scss">
  ul.foot li{
    //font-size:9px!important
    &:hover {
      font-weight:bold!important;
    }
  }


  .btn-float {
    font-size: 28px!important;
  }
  .link {
    cursor: pointer;
  }

  .text--shadow {
    //-webkit-text-stroke: 1px black;
    /* offset-x | offset-y | blur-radius | color */
    text-shadow: 0em .5em .3em rgba(0,0,0,0.6);  
  }
</style>
<style lang="scss" scoped>

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .text--shadow {
    //-webkit-text-stroke: 1px black;
    /* offset-x | offset-y | blur-radius | color */
    text-shadow: 0em .5em .3em rgba(0,0,0,0.6);  
  }

  .scroll {
    animation-name: Scroll;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes Scroll {
    0% { opacity: 0; }
    10% { opacity: 1; }
    //50% { transform: translateY(40px); opacity: 1 }
    100% { transform: translateY(60px); opacity: 0; }
  }



.v-toolbar__content,.v-toolbar__extension {


  .v-btn.v-size--default {
      height: 24px!important;
  }

  .v-btn.v-btn--rounded {
    border-radius: 8px!important;
  }

  .v-btn:before {
      background-color: transparent!important;
      border-radius: inherit;
      bottom: 0;
      color: inherit;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      //transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1); 
  }

  .v-btn:not(.v-btn--active):hover {
    color:#8BC53F!important;
  }

  .v-btn-toggle > .v-btn.v-btn {
    opacity: 1!important;

    transition: all 250ms ease-in-out;


  }

  .v-btn.v-btn--active.btn-nav:not(.btn-line) {
    color: white !important;
    background-color: #8BC53F !important;

    transition: all 250ms ease-in-out;

  }  


  .v-btn.btn-line.v-btn--active.btn-nav {
    color:#8BC53F !important;

    ::after {
        transform: scaleX(1);  
        opacity: 1;
    }

  }


  

}
</style>
