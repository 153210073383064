import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const locale = 'es' // default locale

export default new VueI18n({
  locale,
  messages: {
    'en': {
      conocenos: {
        title: 'KNOW US',
        message:'Exclusive dealer of HeinzSeed in Argentina'

      },
      menu: {
        home: 'Inicio',
        us:'About Us',
        projects:'Projects',
        products:'Products',
        contact:'Contact'
      },
      languages:{
        spanish:'Spanish',
        english:'English'
      },
      whatsapp:"Complete con los datos de su empresa y nos contactaremos con Ud a la brevedad.",
      sliders:{
        slider1:{title:'VARIETY AND QUALITY',subtitle:'HeinzSeed commercializes more than 57 varieties in 40 countries.'},
        slider2:{title:'TECHNICAL ADVICE',subtitle:'We are experts in processing tomato in Argentine  and have specialized professionals of more than 14 years of experience in the market.'},
        slider3:{title:'TOMATO SEEDS MARKET LEADERS',subtitle:'HeinzSeed is the leader in processing tomato seeds in the main tomato producing countries of the world.'}
      },
      about_us:{
        section:'ABOUT US',
        text:"<p>CUYOSEEDS is HeinzSeed exclusive dealer in Argentina since 2018 to date.</p><p>Our goal is to position HeinzSeed in 1st place in the Argentine market as well as in the world's main tomato-producing countries.</p>",
        projects:'Projects',
        catalogue:'Products',
        they_trust_us:'TRUST US',
        zones:'DISTRIBUTION AREAS',
        locations:'<p>Cuyo Seeds commercializes seeds from</p><p>●	Latitude S 29°01´11 in La Rioja to Latitude S 39° 30´46,10 in Río Negro.</p><p>●	From 1100 MASL to 150 MASL.</p>',
        img:'mapa_en.png'
      },
      projects:{
        section:'PROJECTS',
        text:'<p>CUYOSEEDS currently serves customers in the entire industrial productive tomato oasis in Argentina, offering different varietyes according to agro-climatic regional conditions and the industrial needs.</p><p>Our service of technical and commercial advice has more than 14 years of experience in the industrially produced tomato market and work history in Argentina and Chile, serving companies leading the tomato production market worldwide.</p>',
        card1:'INVESTIGATION AND RESEARCH',
        card2:'FIELD DAYS ',
        investigationDevelopment: {
          title:'Investigation and Development',
          text1:'Adaptability trials of new varieties in farmers´ fields, every season, in different productive oasis of the country',
          text2:'Investigation and research of commercial varieties and Nursery in INTA LA CONSULTA, every year.'
        },
        campingDays: {
          title:'Field days',
          //text1:'Field days on commercial farms, showing test plots with test varieties and new materials.',
          text2:'Field day at INTA La Consulta. HeinzSeed participates every year in the traditional processing  tomato day in Argentina.',
          text1:'Field days in commercial fields  which farming and technical professionals participate showing trial plots with new material and commercial varieties.',
          //text2:'Field  day in INTA La Consulta. HeinzSeed  varieties have been part from the beginning of the industrially produced tomato investigation in Argentina and present for more than 20 years in the traditional national event of processing  tomato which takes place between the second and third week of February every year.'
        },

      },
      products:{
        section:'PRODUCTS',
        title:'CUYOSEEDS offers a wide range of seeds that commercializes in the Argentine market.',
        headers:{plant:'Plant', resistance:'Resistance and tolerance', fruit:'Fruit',characteristics:'Characteristics' },
        types:{early:'Early and 2nd early',mid:'Mid',full:'Full'},
        H1015:{
          plant:'Compact with short internodes, very productive. Semi-early cycle, earlier than HM 7883. 110 - 115 days.', 
          resistance:'VFFN  P (Pseudomonas Syringae pv tomato race 0); A (Alternaria alternata f. sp) ; S (Stemphylium spp) ; Cmm (Clavibacter michiganensis subsp michiganensis).',
          fruit:'Medium, blocky. °Brix: High. Firmness: High',
          characteristics:'Compact with short internodes and very good setting. High Brix Firm fruit. Excellent production results in early crops. <b>EFS</b>'},
        H1178:{
          plant:'Vigorous, creeping. Excellent bacterial tolerance. Intermediate cycle, similar to HM 7883. 120 days.', 
          resistance:'VFFN P (Pseudomonas Syringae pv tomato race 0); A (Alternaria alternata f. sp) ; S (Stemphylium spp);  Cmm (Clavibacter michiganensis subsp michiganensis);  Xc (Xanthomonas spp.).', 
          fruit:'Oval, medium, blocky. °Brix: Medium. Firmness: Very high.',
          characteristics:'Vigorous, with good vegetation cover and health. Very firm fruit. <b>EFS</b>'},
        H1301:{
          plant:'Vigorous, very productive, high setting. Excellent bacterial tolerance. Early cycle. 110 days.', 
          resistance:'VFF   P (Pseudomonas Syringae pv tomato race 0);  A ( Alternaria alternata f. sp) ;  S (Stemphylium spp); Eb (Alternaria Solani); Cmm (Clavibacter michiganensis subsp michiganensis); Xc (Xanthomonas spp).', 
          fruit:'Oval, medium to small, blocky. °Brix: High',
          characteristics:'Vigorous plant, with good vegetation cover. Ideal for beginning and end of season.  Excellent maturity concentration. Short cycle with Alternaria Solani and bacterial (Clavibacter and Xanthomonas) tolerance. <b>EFS</b>'
        },
        H1307:{
          plant:'Vigorous with good vegetation cover. Alternaria solani and bacterial tolerance. 120 - 125 days cycle', 
          resistance:'VFFN A (Alternaria alternata f. sp); S (Stemphylium spp); Eb (Alternaria Solani); Xc (Xanthomonas spp)', 
          fruit:'Medium, blocky, even. <b>°Brix: Very High.</b> Firmness: good.',
          characteristics:'Vigorous plant with an excellent adaptability to humid environments. All-purpose fruit with very high °Brix. <b>EFS.</b>'
        },
        H1879:{
          plant:'Medium vigor, with good vegetation cover. Good tolerance to disease and bacteria.', 
          resistance:'VFFN P (Pseudomonas Syringae pv tomato race 0); TSW (Tomato spotted wilt virus); Cmm (Clavibacter michiganensis subsp michiganensis); Xc (Xanthomonas spp). ', 
          fruit:'Oval, medium, blocky. °Brix: High',
          characteristics:'Plant with short internodes, Production fruit concentrated on the bed. Resistance to Pseudomonas, Xanthomonas and TSWV virus. Semi-early cycle. <b>EFS</b>'},
        H1881:{
          plant:'Vigorous plant, with good vegetation cover. Good tolerance to disease and bacteria.', 
          resistance:'VFFN P (Pseudomonas Syringae pv tomato race 0); TSW (Tomato spotted wilt virus); Cmm (Clavibacter michiganensis subsp michiganensis); Xc (Xanthomonas spp). ', 
          fruit:'Oval, medium to large, blocky. °Brix: Medium',
          characteristics:'Vigorous plant, with short internodes, very productive. Production fruit concentrated on the bed. Resistance to Pseudomonas, Xanthomonas and TSWV virus. Intermediate cycle.'},
        H7709:{
          plant:'Vigorous, erect. Good disease tolerance. 125 - 130 days long cycle.', 
          resistance:'VFFN A (Alternaria alternata f. sp); S (Stemphylium spp).', 
          fruit:'Large, blocky. °Brix: High. Firmness: medium.',
          characteristics:'Vigorous plant, with high productive potential. Excellent setting in early crops from San Juan and late crops from Valle de Uco. Large fruit for paste and dried products.'
        },
      },
      contact:{
        section:'CONTACT US',
        text:'We´ll respond as soon as possible to your request',
        name:'Name',
        email:'Email',
        phone:'Phone',
        company:'Company',
        inquiry:'Inquiry',
        send:'Send',
        error:{
          name:{required:'Name is required'},
          email:{
            required:'Email is required',
            invalid:'Email is invalid'
          },
          company:{required:'Campany is required'},
          inquiry:{required:'Inquiry is required'}
        },
        sendStatus:{success:'Your query has been sent, we will contact you shortly.',error:'Your query could not be sent, please try again.'}
      },
    },
    'es': {
      conocenos: {
        title: 'CONOCENOS',
        message:'Representante HeinzSeed en Argentina'
      },
      menu: {
        home: 'Inicio',
        us:'Nosotros',
        projects:'Proyectos',
        products:'Productos',
        contact:'Contacto'
      },
      languages:{
        spanish:'Español',
        english:'Ingles'
      },
      whatsapp:"Complete con los datos de su empresa y nos contactaremos con Ud a la brevedad.",
      sliders:{
        slider1:{title:'CALIDAD Y COMPROMISO',subtitle:'HeinzSeed comercializa más de 57 variedades en 40 países.'},
        slider2:{title:'ASESORAMIENTO TÉCNICO',subtitle:'Contamos con  profesionales  especializados con más de 14 años de experiencia en el rubro.'},
        slider3:{title:'LÍDERES EN SEMILLAS DE TOMATE',subtitle:'HeinzSeed es líder en semillas de tomate en los principales países productores de tomate del mundo.'}
      },
      about_us:{
        section:'QUIÉNES SOMOS',
        text:'<p>CUYOSEEDS , es representante de HeinzSeed en Argentina desde el año 2018, a la fecha.</p><p>Nuestro objetivo es liderar el mercado de semillas de tomate industrial en la Argentina, con las variedades HeinzSeed, al igual que en los principales países productores de tomate en el mundo.</p>',
        projects:'Proyectos',
        catalogue:'Catálogo',
        they_trust_us:'CONFÍAN EN NOSOTROS',
        zones:'ZONAS DE DISTRIBUCIÓN',
        img:'mapa_es.png'
      },
      projects:{
        section:'PROYECTOS',
        text:'<p>CUYOSEEDS actualmente atiende clientes en todos los oasis productivos de tomate industrial de la Argentina, ofreciendo diferentes alternativas varietales, de acuerdo a las condiciones agroclimáticas de la región y las necesidades de la Industria.</p><p>Nuestro servicio de asesoramiento técnico comercial, cuenta con experiencia de más de 14 años en el rubro de tomate industrial con antecedentes laborales en Argentina y Chile, trabajando con empresas líderes en producción de tomate a nivel mundial.</p>',
        card1:'INVESTIGACIÓN Y DESARROLLO',
        card2:'DÍAS DE CAMPO',
        investigationDevelopment: {
          title:'INVESTIGACIÓN Y DESARROLLO',
          text1:'Ensayos de adaptabilidad de nuevas variedades en campos  de agricultores, en cada temporada  en los diferentes oasis productivos del país.',
          text2:'Investigación y desarrollo de variedades comerciales y Nursery en INTA LA CONSULTA, todos los años.'
        },
        campingDays: {
          title:'DÍAS DE CAMPO',
          text1:'Días de campo en fincas comerciales, con participación de agricultores y técnicos del sector, mostrando parcelas de ensayos con variedades comerciales y nuevos materiales.',
          text2:'Día de campo en INTA La Consulta. Heinz participa desde los inicios de la investigación del tomate industrial en Argentina, desde hace más de 20 años en el tradicional evento de tomate para industria del país.'
        },

      },
      products:{
        section:'PRODUCTOS',
        title:'CUYO SEEDS dispone de un amplio catálogo de semillas que comercializa en el mercado Argentino.',
        headers:{plant:'Planta', resistance:'Resistencias y tolerancias', fruit:'Frutos',characteristics:'Características' },
        types:{early:'Semi-precoces',mid:'Ciclo medio',full:'Ciclo tardío'},
        H1015:{
          plant:'Compacta con entrenudos cortos , muy productiva. Ciclo semi precoz, más temprana que HM 7883.  110 - 115 días.',
          resistance:'VFFN  P (Pseudomonas Syringae pv tomato race 0); A (Alternaria alternata f. sp); S (Stemphylium spp); Cmm (Clavibacter michiganensis subsp michiganensis).',
          fruit:'Tipo Blocky mediano. °Brix  Alto. Firmeza: Alta',
          characteristics:'Planta compacta, con entrenudos cortos y muy buen cuaje. Frutos firmes de altos °Brix. Excelentes resultados productivos en cultivos tempranos. <b>EFS</b>'
        },
        H1178:{
          plant:'Vigorosa de hábito rastrero. Excelente tolerancia a bacterias. Ciclo intermedio, similar HM 7883. 120 días.', 
          resistance:'VFFN P (Pseudomonas Syringae pv tomato race 0); A (Alternaria alternata f. sp); S (Stemphylium spp); Cmm (Clavibacter michiganensis subsp michiganensis);  Xc (Xanthomonas spp.).', 
          fruit:'Tipo Blocky/Oval  mediano. °Brix  Medio. Firmeza: Muy alta.',
          characteristics:'Planta vigorosa, con buena cobertura vegetativa y sanidad. Fruto muy firme. <b>EFS</b>'
        },
        H1301:{
          plant:'Vigorosa,  muy productiva con alto cuaje. Excelente tolerancia a bacterias.. Ciclo precoz. 110 días.', 
          resistance:'VFF   P (Pseudomonas Syringae pv tomato race 0);  A ( Alternaria alternata f. sp); S (Stemphylium spp); Eb (Alternaria Solani); Cmm (Clavibacter michiganensis subsp michiganensis); Xc (Xanthomonas spp).', 
          fruit:'Tipo Blocky/Oval  mediano a chico. °Brix : Alto',
          characteristics:'Planta vigorosa, con buena cobertura vegetativa. Ideal  para inicio o final  de temporada.  Excelente concentración de madurez. Ciclo corto con tolerancia  a Alternaria Solani  y bacterias (Clavibacter y Xanthomonas). <b>EFS</b>'
        },
        H1307:{
          plant:'Vigorosa con buena cobertura vegetativa. Tolerancia a bacterias y  Alternaria solani. Ciclo 120 - 125 días.', 
          resistance:'VFFN A (Alternaria alternata f. sp); S (Stemphylium spp); Eb (Alternaria Solani); Xc (Xanthomonas spp)', 
          fruit:'Tipo blocky mediano muy uniformes. <b>°Brix  Muy Alto</b>. Firmeza: Buena.',
          characteristics:'Planta vigorosa, con excelente adaptabilidad  a ambientes húmedos. Fruto multipropósito con muy altos°Brix. <b>EFS.<b>'
        },
        H1879:{
          plant:'Vigor medio, con buena cobertura vegetativa. Buena tolerancia a bacterias y enfermedades.',
          resistance:'VFFN P (Pseudomonas Syringae pv tomato race 0);  TSW (Tomato spotted wilt virus); Cmm (Clavibacter michiganensis subsp michiganensis); Xc (Xanthomonas spp). ', 
          fruit:'Tipo blocky mediano muy uniformes. <b>°Brix  Muy Alto</b>. Firmeza: Buena.',
          characteristics:'Planta, con entrenudos cortos, con la producción concentrada sobre la cama. Resistencia a Pseudomonas, Xanthomonas y Virus TSWV. Ciclo semi precoz. <b>EFS</b>'
        },
        H1881:{
          plant:'Vigorosa, con buena cobertura vegetativa. Buena tolerancia a bacterias y enfermedades.', 
          resistance:'VFFN P (Pseudomonas Syringae pv tomato race 0);  TSW (Tomato spotted wilt virus); Cmm (Clavibacter michiganensis subsp michiganensis); Xc (Xanthomonas spp). ', 
          fruit:'Tipo blocky/oval de tamaño medio a grande.°Brix  Medio',
          characteristics:'Planta vigorosa, con entrenudos cortos, muy productiva. producción concentrada sobre la cama. Resistencia a Pseudomonas, Xanthomonas y Virus TSWV. Ciclo intermedio.'
        },
        H7709:{
          plant:'Vigorosa de hábito erecto. Buena tolerancia a enfermedades. Ciclo largo 125 - 130 días.', 
          resistance:'VFFN A (Alternaria alternata f. sp); S (Stemphylium spp).', 
          fruit:'Tipo blocky grande. °Brix  Alto. Firmeza: Media',
          characteristics:'Planta vigorosa, con alto potencial productivo. Excelente cuaje en cultivos tempranos de San Juan y Tardío del Valle de Uco. Fruto  grande para pasta y desecado.'
        },

      },
      contact:{
        section:'CONTÁCTESE CON NOSOTROS',
        text:'Responderemos lo antes posible a su solicitud.',
        name:'Nombre',
        email:'Email',
        phone:'Teléfono',
        company:'Compañía',
        inquiry:'Consulta',
        send:'Enviar',
        error:{
          name:{required:'El campo nombre es obligatorio'},
          email:{required:'El campo email es obligatorio',invalid:'Ingrese un email valido'},
          company:{required:'el campo compania es obligatorio'},
          inquiry:{required:'El campo consulta es obligatorio'}
        },
        sendStatus:{success:'Su consulta ha sido enviada, nos comunicaremos con usted a la brevedad.',error:'Su consulta no pudo ser enviada, intente nuevamente.'}
      },
    }
  }
})