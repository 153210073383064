<template>
  <div class="mt-12" >

    <v-row>
      <v-col cols="10" md="6" class="offset-1 ">
          <p class="text-h6 font-weight-bold">{{ $t('contact.section') }}</p>
          <p v-html="$t('contact.text')"></p>
          <div>

            <v-row no-gutters >
              <v-col cols="12" class="d-flex text-black flex-column flex-md-row"  >
                <div>
                  <span>
                    <v-icon small color="black darken-2">mdi-map-marker</v-icon>
                  </span>
                  <span class="px-2 body-2">Videla Castillo N° 2077 RusseL,Maipú, Provincia de Mendoza</span>
                </div>
                <v-divider 
                  :vertical="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
                  class="my-5 my-md-0 mx-0 mx-md-5"
                >
                </v-divider>
                <div>
                  <p class="d-flex px-2">
                    <span>
                      <v-icon small color="black darken-2">mdi-cellphone-iphone</v-icon>
                    </span>
                    <span class="px-2 body-2" >(+54 9) 261 3020767</span>
                  </p>
                  <p class="d-flex px-2">
                    <span>
                      <v-icon small color="black darken-2">@</v-icon>
                    </span>
                    <span class="px-2 body-2">info@cuyoseeds.com.ar</span>
                  </p>
                </div>
              </v-col>
            </v-row>

          </div>
      </v-col>
      <v-col cols="10" md="4" class="offset-1 offset-md-0 ">
          <v-row  no-gutters class=" black--text ">
            <v-col cols=12 class="">
              <v-text-field 
                required
                dense
                filled
                v-model="frm.nombre"
                @input="$v.frm.nombre.$touch()"
                @blur="$v.frm.nombre.$touch()"

                :error-messages="nombreErrors"
                  
                persistent-placeholder
                :label="$t('contact.name')" 
                placeholder=""
                color="gray"
                class="text-body-2"
              >
              </v-text-field>
            </v-col>
            <v-col cols=12>
              <v-text-field 
                dense 
                filled
                :label="$t('contact.email')"

                :error-messages="emailErrors"

                required
                v-model="frm.email"
                @input="$v.frm.email.$touch()"
                @blur="$v.frm.email.$touch()"
                persistent-placeholder
                placeholder=""
                color="gray"
                class="text-body-2"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field 
                dense 
                filled
                persistent-placeholder
                :label="$t('contact.phone')"               
                v-model="frm.tel"
                placeholder=""
                color="gray"
                class="text-body-2"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field 
                dense 
                filled
                persistent-placeholder
                :label="$t('contact.company')" 
                required

                :error-messages="empresaErrors"
                @input="$v.frm.empresa.$touch()"
                @blur="$v.frm.empresa.$touch()" 

                v-model="frm.empresa"
                placeholder=""
                color="gray"
                class="text-body-2"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea 
                dense 
                filled
                persistent-placeholder
                :label="$t('contact.inquiry')" 
                
                v-model="frm.texto"
                placeholder=""
                :counter="150"
                rows="3"

                :error-messages="textoErrors"
                required
                @input="$v.frm.texto.$touch()"
                @blur="$v.frm.texto.$touch()" 
                color="gray"
                class="text-body-2"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn small color="secondary lighten-2" class="white--text" @click="sendEmail" :loading="sendingEmail">{{$t('contact.send')}}</v-btn>
            </v-col>
          </v-row>
              <v-snackbar
                v-model="notifEmail.open"
                timeout="4000"
                :color="notifEmail.color"
                
                
              >
                {{ notifEmail.text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="notifEmail.open = false"
                  >
                    X
                  </v-btn>
                </template>
              </v-snackbar>
      </v-col>
    </v-row>

  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email,numeric } from 'vuelidate/lib/validators'

export default {
  name: 'Contacto',
  mixins: [validationMixin],

  validations: {

    frm: {
      nombre:{required,maxLength: maxLength(50),minLength:minLength(3)},  
      email: {required, email,maxLength: maxLength(80)},
      empresa:{required,maxLength: maxLength(50),minLength:minLength(3)}, 
      tel:{},
      texto:{required,maxLength: maxLength(150),minLength:minLength(3)}

    } 

  },


  data: () => ({

    frm: {
        nombre:'',  
        email:'',
        tel:'',
        empresa:'',
        texto:''
    },
    sendingEmail:false,
    notifEmail:{open:false,type:'',text:''},
  }),

created: async function () {
    this.$store.commit('setMenu',3)
    //console.log('create app')

    //console.log(this.$vuetify.breakpoint)
    //this.offsetTop=window.scrollY
    
      
  },

  mounted: function (){
    //console.log("routes-->", this.$route)
    //let {name,params,fullpath,path} = this.$route 
    //if (name=='Secciones') this.$nextTick( function () { this.$vuetify.goTo(`#${params.seccionID}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})})
  },

  watch: {

  },
  computed:{
    //...mapGetters(['ROOT_API','MENU','getSnackBar','getSESSION','getUser']),


    //INI VALIDA FORMULARIO
      emailErrors () {
        const errors = []
        if (!this.$v.frm.email.$dirty) return errors

        !this.$v.frm.email.required && errors.push(this.$t('contact.error.email.required'))
        !this.$v.frm.email.email && errors.push(this.$t('contact.error.email.invalid'))
        !this.$v.frm.email.maxLength && errors.push(this.$t('contact.error.email.invalid'))

        return errors
      },

      nombreErrors () {
        const errors = []
        if (!this.$v.frm.nombre.$dirty) return errors
        !this.$v.frm.nombre.maxLength && errors.push('Este campo debe tener como máximo 50 caracteres')
        !this.$v.frm.nombre.minLength && errors.push('Este campo debe tener como mínimo 3 caracteres')
        !this.$v.frm.nombre.required && errors.push(this.$t('contact.error.name.required'))
        return errors
      },

      textoErrors () {
        const errors = []
        if (!this.$v.frm.texto.$dirty) return errors
        !this.$v.frm.texto.maxLength && errors.push('Este campo debe tener como máximo 150 caracteres')
        !this.$v.frm.texto.minLength && errors.push('Este campo debe tener como mínimo 3 caracteres')
        !this.$v.frm.texto.required && errors.push(this.$t('contact.error.inquiry.required'))
        return errors
      },

      empresaErrors () {
        const errors = []
        if (!this.$v.frm.empresa.$dirty) return errors
        !this.$v.frm.empresa.required && errors.push(this.$t('contact.error.company.required'))
        !this.$v.frm.empresa.maxLength && errors.push('Este campo debe tener formato válido')
        !this.$v.frm.empresa.minLength && errors.push('Este campo debe tener formato válido')
        return errors
      },
    //FIN VALIDA FORMULARIO

  },

  methods: {

    onScroll (e){
      //console.log({e, window})
    },

    async sendEmail(){
      try{
        this.$v.frm.$touch()
        if (!this.$v.frm.$invalid) {
          this.sendingEmail=true

          let params=this.frm

          let opt={
            method: 'POST', 
            mode: 'cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },  
            body: JSON.stringify(params),
          }

          let response = await fetch('https://api.cuyoseeds.com.ar/contact', opt)
          if (!response.ok) throw 'No se pudo enviar el email'


          this.notifEmail={open: true, color:'primary',text:'Se envio su consulta en forma exitosa!'}

          this.frm={nombre:'',email:'',tel:'',empresa:'',texto:''}
          this.$v.$reset()

        }
      
      }
      catch (err){
        this.notifEmail={open: true, color:'error',text:'No se puedo enviar su consulta!'}
      }
      finally {
        this.sendingEmail=false
      }

    }

  },




}
</script>

<style lang="scss" scoped>

</style>
