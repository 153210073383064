<template>
  <div class="mt-5">

    <v-row no-gutters class="mb-5">
      <v-col cols="10" class="offset-1">
        <div class="d-flex align-center">
          <v-img 
            src="/images/icono_productos.png"
            max-width="42"
          >
          </v-img>
          <span class="mx-5 text-h6 font-weight-bold">{{ $t('products.section') }}</span>

        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10" class="offset-1">
        
        <v-item-group
          v-model="typeID"                         
          mandatory
          group
          class="caption"
        >
          <v-item v-for="i of types"  class="my-5 my-md-0 mr-5 text-body-1 mb-2 font-weight-bold" :class="typeID==i.id ? 'primary':''"  :value="i.id" :key="i.id">
            <v-btn class="py-8" :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm "  @click="typeID=i.id">
              {{$t(`products.types['${i.type}']`)}}
            </v-btn>
          </v-item>
        </v-item-group>
      </v-col>
      <v-col cols="10" class="offset-1 px-0 py-2"><span>{{ $t('products.title') }}</span></v-col>
    </v-row>
    <v-row no-gutters >
      <v-col cols="10" class="offset-1 px-0">
        <v-row >
          <v-col cols="12" sm="6" md=4  lg="3"  v-for="(n, index) in Productos" :key="index" class="">
            <v-sheet
              color="grey lighten-4"
              min-height="250px"
              max-height="300px"
              height="100%"
              class=" rounded-tl-xl rounded-bl-xl rounded-br-xl card_producto link  "
              :style='`background-image:url("https://www.cuyoseeds.com.ar${n.img}");`'
              @click="item=n"
            >
              <div class="py-3 hover white--text font-weight-bold text-center text-h6" >{{n.codigo}}</div>
            
            </v-sheet>
          </v-col>
         
        </v-row>
      </v-col>
     
    </v-row>
    <Producto v-if="item" :item="item" @close="item=null"></Producto>
  </div>
</template>

<script>
// @ is an alias to /src
import Producto from '@/components/Producto'

export default {
  name: 'Productos',
  components: {Producto},
  data: () => ({
    typeID:0,
    types:[{id:1,type:'early'},{id:2,type:'mid'},{id:3,type:'full'}],
    productos:[
      {codigo:'H1015', text:"HEINZ 1015",typeID:1,img:'/images/products/H1015/H1015.jpg',imgs:['H1015.jpg','IMG_1015_1.jpg','IMG_1015_2.jpg','IMG_1015_3.jpg']},
      {codigo:'H1178', text:"HEINZ 1178",typeID:2,img:'/images/products/H1178/H1178.jpg',imgs:['H1178.jpg','IMG_1178_1.jpg','IMG_1178_2.jpg','IMG_1178_3.jpg']},
      {codigo:'H1301', text:"HEINZ 1301",typeID:1,img:'/images/products/H1301/H1301.jpg',imgs:['H1301.jpg','IMG_1301_1.jpg','IMG_1301_2.jpg','IMG_1301_3.jpg','IMG_1301_1.jpg']},
      {codigo:'H1307', text:"HEINZ 1307",typeID:2,img:'/images/products/H1307/H1307.jpg',imgs:['H1307.jpg','IMG_1307_2.jpg']},
      {codigo:'H1879', text:"HEINZ 1879",typeID:1,img:'/images/products/H1879/H1879.jpg',imgs:['H1879.jpg','IMG_1879_1.jpg']},
      {codigo:'H1881', text:"HEINZ 1881",typeID:2,img:'/images/products/H1881/H1881.jpg',imgs:['H1881.jpg','IMG_1881_1.jpg','IMG_1881_2.jpg','IMG_1881_3.jpg','IMG_1881_4.jpg','IMG_1881_5.jpg']},
      {codigo:'H7709', text:"HEINZ 7709",typeID:3,img:'/images/products/H7709/H7709.jpg',imgs:['H7709.jpg','IMG_7709_1.jpg','IMG_7709_2.jpg','IMG_7709_3.jpg','IMG_7709_4.jpg']},
    ],
    item:null,
  }),

created: async function () {
    this.$store.commit('setMenu',2)
    //console.log('create app')

    //console.log(this.$vuetify.breakpoint)
    //this.offsetTop=window.scrollY
    
      
  },

  mounted: function (){
    //console.log("routes-->", this.$route)
    //let {name,params,fullpath,path} = this.$route 
    //if (name=='Secciones') this.$nextTick( function () { this.$vuetify.goTo(`#${params.seccionID}`,{duration: 600,offset: 0, easing: 'easeInOutCubic'})})
  },

  watch: {

  },
  computed:{
    //...mapGetters(['ROOT_API','MENU','getSnackBar','getSESSION','getUser']),

    Productos: function (){
      return this.productos.filter(x=>x.typeID==this.typeID)
    },

    onResize: function () {
      console.log("onResize HOME",this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint  
      let  orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation    

      console.log("orientation-->",orientation)
      console.log({xs, sm, md, lg, xl,mobile})
      console.log("screen-->",screen)

      if (orientation=='landscape-primary' ) {
       // ( sm || xs || screen.height<520) ? this.heightSlide='100vh' : this.heightSlide='63vh' 
      }
      else {
        //this.heightSlide='63vh' 
      }

      return sm || xs


      //this.$store.dispatch("resizeApp",{ x: window.innerWidth, y: window.innerHeight, xs, sm, md, lg, xl,mobile })
    },

   

  },

  methods: {

    onScroll (e){
      //console.log({e, window})
    },

  },




}
</script>

<style lang="scss" scoped>

.card_producto {
  background-size: cover; /*Con esto ocupas el 100% del contenedor*/
  background-repeat: no-repeat;  /*Evitas que la imagen se repita si el contenedor es mas grande*/
  /*Ten en cuenta que la ruta es relativa y empezara desde el lugar donde tengas el css*/
  //background-image:url("https://www.cuyoseeds.com.ar/images/projects/dias_campo.jpg"); 
  background-position: 100% 100%;
  position: relative;

  .hover {
    background-color: rgba(147,213,0,.9);
    top: calc(50% - 25px);
    position: relative;
    height: 50px;
  }

  &:hover {
    .hover {
      background-color: rgba(147,213,0,1);
    }
  }



}





  

</style>
