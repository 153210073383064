<template>
  <div class="mt-5" >

    <v-row no-gutters class="mb-5">
      <v-col cols="10" class="offset-1">
        <div class="d-flex align-center">
          <v-img 
            src="/images/icono_proyectos.png"
            max-width="42"
          >
          </v-img>
          <span class="mx-5 text-h6 font-weight-bold">{{ $t('projects.section') }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10" md="4" class="offset-1">
        <v-row  no-gutters>
          <v-col cols="12"  v-html="$t('projects.text')"></v-col>
        </v-row>
      </v-col>
      <v-col cols="10" md="6" class="offset-1 offset-md-0">
        <v-row >
          <v-col cols="12"  md="6" class="d-flex justify-center ">
            <v-sheet
              color="grey lighten-4"
              min-height="300px"
              max-height="300px"
              max-width="400px"
              height="100%"
              width="100%"
              class=" rounded-tl-xl rounded-bl-xl rounded-br-xl card_investigacion link"
              @click="currentComponent='InvestigacionDesarrollo'"
            >
              <div class="px-3 rounded-tl-xl py-3 hover white--text font-weight-bold text-center " >{{$t('projects.card1')}}</div>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-center ">
            <v-sheet
              color="grey lighten-4"
              min-height="300px"
              max-height="300px"
              max-width="400px"
              
              height="100%"
              width="100%"
              class="rounded-tl-xl rounded-bl-xl rounded-br-xl card_dia link"
              @click="currentComponent='DiasCampo'"
            >
              <div class="px-3 rounded-tl-xl py-3 hover white--text font-weight-bold text-center " >{{$t('projects.card2')}}</div>
            </v-sheet>
          </v-col>

        </v-row>

      
      </v-col>

    </v-row>

    <!-- INI DIALOG -->
    <component v-if="currentComponent" :is="currentComponent" @close="currentComponent=null"></component>
    <!-- FIN DIALOG -->


  </div>
</template>

<script>
// @ is an alias to /src

import InvestigacionDesarrollo from '@/components/InvestigacionDesarrollo'
import DiasCampo from '@/components/DiasCampo'

export default {
  name: 'Proyectos',
  components: {
    InvestigacionDesarrollo,
    DiasCampo
  },
  data: () => ({
    currentComponent:null,
  }),

created: async function () {
    this.$store.commit('setMenu',1)
    //console.log('create app')

    //console.log(this.$vuetify.breakpoint)
    //this.offsetTop=window.scrollY
    
      
  },

  mounted: function (){

  },

  watch: {

  },

computed:{
    //...mapGetters(['ROOT_API','MENU','getSnackBar','getSESSION','getUser']),


    onResize: function () {
      console.log("onResize HOME",this.$vuetify.breakpoint)
      let {xs, sm, md, lg, xl,mobile} =  this.$vuetify.breakpoint  
      let  orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation    

      console.log("orientation-->",orientation)
      console.log({xs, sm, md, lg, xl,mobile})
      console.log("screen-->",screen)

      if (orientation=='landscape-primary' ) {
       // ( sm || xs || screen.height<520) ? this.heightSlide='100vh' : this.heightSlide='63vh' 
      }
      else {
        //this.heightSlide='63vh' 
      }

      return sm || xs


      //this.$store.dispatch("resizeApp",{ x: window.innerWidth, y: window.innerHeight, xs, sm, md, lg, xl,mobile })
    },

  },

  methods: {

  },




}
</script>

<style lang="scss" scoped>

.card_dia {
  background-size: cover; /*Con esto ocupas el 100% del contenedor*/
  background-repeat: no-repeat;  /*Evitas que la imagen se repita si el contenedor es mas grande*/
  background-image:url("https://www.cuyoseeds.com.ar/images/projects/dias_campo.jpg"); /*Ten en cuenta que la ruta es relativa y empezara desde el lugar donde tengas el css*/
  background-position: 100% 100%;
  position: relative;
}

.card_investigacion {
  background-size: cover; /*Con esto ocupas el 100% del contenedor*/
  background-repeat: no-repeat;  /*Evitas que la imagen se repita si el contenedor es mas grande*/
  background-image:url("https://www.cuyoseeds.com.ar/images/projects/investigacion_desarrollo.jpg"); /*Ten en cuenta que la ruta es relativa y empezara desde el lugar donde tengas el css*/
  background-position: 100% 100%;
  position: relative;
  
}

.hover {
  background-color: rgba(0,0,0,.7);
}

.zona {
  width: 35px;
  height: 50px;
  background-color:red;
  display:flex;
  justify-content: center;
  align-items:center;
  color:white;
  position: relative;

  font-size: 24px;
  font-weight: bold;
  font-family: 'Poppins';


  &::before {
    position:absolute;
    content: "";
    width:30px;
    height:50px;
    background-color:red;
    left:24px;
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -ms-transform: skew(-25deg);
    -o-transform: skew(-25deg);
    transform: skew(-25deg), 

    
  }
  
}
</style>
